import ng from 'angular';
import acpCore from 'core';
import acpUpgradeLoggingClient from './client';

// @ngInject
function acpUpgradeLoggingModel(acpUpgradeLoggingClient) {
  function UpgradeLoggingModel() {}

  UpgradeLoggingModel.prototype = {
    stepName: null,
    flowName: null,

    logFlowCancelled: function(logRequest) {
      var cancelledFlowLogRequest = {
        step_name: logRequest.stepName,
        flow_name: logRequest.flowName
      };

      return acpUpgradeLoggingClient.flowCancelled(cancelledFlowLogRequest);
    },

    logFlowStarted: function() {
      return acpUpgradeLoggingClient.flowStarted({});
    }
  };

  return function createUpgradeLoggingModel() {
    return new UpgradeLoggingModel();
  };
}

export default ng
  .module('acp.shared.services.acpUpgradeLoggingModel', [acpCore.name])
  .factory('acpUpgradeLoggingModel', acpUpgradeLoggingModel)
  .service('acpUpgradeLoggingClient', acpUpgradeLoggingClient);
