// @ngInject
function acpUpgradeLoggingClient(webapiResource) {
  var client = {};

  client.flowCancelled = webapiResource({
    path: '/v2/productupgrade/flow-cancelled',
    format: 'json',
    method: 'POST'
  });

  client.flowStarted = webapiResource({
    path: '/v2/productupgrade/flow-started',
    format: 'json',
    method: 'POST'
  });

  return {
    flowStarted: client.flowStarted,
    flowCancelled: client.flowCancelled
  };
}

export default acpUpgradeLoggingClient;
